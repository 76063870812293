import { ArrowLeft, ArrowRight, Users } from '@phosphor-icons/react'
import {
  AvatarButtonGroup,
  Button,
  Dropdown,
  Link,
  Tag,
  Tooltip,
} from 'src/design-system'
import { format } from 'date-fns'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import cn from 'classnames'
import { avatarGroupProps } from 'app/packs/src/utils/user-helpers'
import { Checkin } from 'store/modules/checkins'
import { CHECKIN_SUMMARY_PAGE_TRACK_EVENT_NAME } from '../utils'
import { store } from 'store/index'
import { trackEvent } from 'app/packs/src/services/event-tracker'
import * as SecondaryNav from 'components/secondary-nav'
import styles from './styles.module.scss'

type HeaderProps = {
  checkin: Checkin
  nextCheckin?: Checkin
  previousCheckin?: Checkin
}

export const Header = observer((props: HeaderProps) => {
  const { checkin, nextCheckin, previousCheckin } = props

  let tooltipContent = `${checkin.author.fname}, ${checkin.author.fname}'s reporting line and admins`

  if (store.currentUser?.id === checkin.author.id) {
    tooltipContent = 'You, your reporting line and admins'
  }

  const onClick = (action: string) => {
    trackEvent(CHECKIN_SUMMARY_PAGE_TRACK_EVENT_NAME, { action })
  }

  const onPdfClick = () => {
    onClick('download-pdf')
    const bodyElement = document.querySelector('body')

    // Remove the scroll lock that Radix drop down menu adds. The lock breaks the print layout.
    if (bodyElement) {
      bodyElement.removeAttribute('data-scroll-locked')
      bodyElement.style.pointerEvents = ''
    }
    window.print()
  }

  return (
    <SecondaryNav.Root className="h-32 md:h-24 print-hide">
      <SecondaryNav.Body className="w-full">
        <div className="flex flex-col md:flex-row gap-y-4 justify-between w-full">
          <div className="flex gap-4">
            <AvatarButtonGroup
              avatarClassName="border-white border-px border-solid"
              avatars={avatarGroupProps(checkin.users, {
                stateTooltip: true,
              })}
              size="md"
            />
            <div className="flex flex-col gap-1 justify-center">
              <div className="flex gap-2">
                <h5 className="font-bold text-gray-900 text-base">
                  {checkin.author.fullName}&apos;s Check-in
                </h5>
                <Tag variant="paper">
                  <Tooltip content={tooltipContent}>
                    <Users className="h-3 text-gray-600 w-3" weight="bold" />
                  </Tooltip>
                </Tag>
              </div>
              <p className="mb-0 text-gray-600 text-sm">
                {checkin.positionName ? `${checkin.positionName} · ` : ''}
                {checkin.assessor ? `${checkin.assessor.fullName} · ` : ''}
                {checkin.finalisedAt &&
                  format(checkin.finalisedAt, 'dd MMM yyyy')}
              </p>
            </div>
          </div>
          <div className="flex items-center gap-3">
            <div className="flex items-center gap-1">
              <PreviousNextCheckinButton checkin={previousCheckin}>
                <ArrowLeft className="h-4 text-gray-600 w-4" weight="bold" />
                <span className="sr-only">Previous Check-in</span>
              </PreviousNextCheckinButton>
              <PreviousNextCheckinButton checkin={nextCheckin}>
                <ArrowRight className="h-4 text-gray-600 w-4" weight="bold" />
                <span className="sr-only">Next Check-in</span>
              </PreviousNextCheckinButton>
            </div>
            <Button
              as="a"
              colourVariant="theme"
              data-turbo={false}
              href={`/check-ins`}
              onClick={() => onClick('all-checkins')}
            >
              All Check-ins
            </Button>
            <Dropdown.Root>
              <Dropdown.Trigger
                className={cn(
                  'border-theme-40 btn flex h-8 p-2 text-theme-40',
                  styles.downloadButton
                )}
              >
                Download
              </Dropdown.Trigger>
              <Dropdown.Content className="w-[102px]">
                <Dropdown.Button
                  className="h-9 p-0 w-full"
                  onClick={() => onClick('download-csv')}
                >
                  <Link
                    className="block p-2 text-gray-900 w-full"
                    href={`${checkin.path}/csv`}
                    isExternal
                    underline={false}
                  >
                    CSV
                  </Link>
                </Dropdown.Button>
                <Dropdown.Button
                  className="h-9 p-2 w-full"
                  onClick={onPdfClick}
                >
                  PDF
                </Dropdown.Button>
              </Dropdown.Content>
            </Dropdown.Root>
          </div>
        </div>
      </SecondaryNav.Body>
    </SecondaryNav.Root>
  )
})

const PreviousNextCheckinButton: React.VFC<{
  checkin?: Checkin
  children: React.ReactNode
}> = (props) => {
  const { checkin, children } = props

  if (checkin) {
    return (
      <Button
        as="a"
        data-turbo-action="advance"
        className="border-gray-100"
        href={checkin.path}
        variant="outline"
      >
        {children}
      </Button>
    )
  }

  return (
    <Button
      as="button"
      className="border-gray-100 opacity-50"
      disabled
      variant="outline"
    >
      {children}
    </Button>
  )
}
