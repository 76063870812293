import Select, { components, DropdownIndicatorProps } from 'react-select'
import * as React from 'react'
import { CaretDown } from '@phosphor-icons/react'

type ComparePositionSelectGroupOption = {
  label: string
  options: ComparePositionSelectOption[]
}

export type ComparePositionSelectOption = { label: string; value: string }

export type ComparePositionSelectProps = {
  defaultValue?: ComparePositionSelectOption
  disabled?: boolean
  onChange?: (positionId: string | null) => void
  options: ComparePositionSelectGroupOption[]
  placeholder?: string
}

export const ComparePositionSelect: React.VFC<ComparePositionSelectProps> = (
  props
) => {
  const {
    defaultValue,
    disabled,
    onChange,
    options,
    placeholder = 'Select a position',
  } = props

  return (
    <div className="relative">
      <Select
        closeMenuOnSelect
        components={{ DropdownIndicator }}
        defaultValue={defaultValue}
        isDisabled={disabled}
        isSearchable={false}
        onChange={(newValue) => {
          onChange?.((newValue as ComparePositionSelectOption)?.value)
        }}
        options={options}
        placeholder={placeholder}
        styles={{
          container: () => ({
            fontSize: 16,
            height: 44,
            width: '100%',
          }),
          control: (baseStyles) => ({
            ...baseStyles,
            borderColor: '#CECACA !important',
            boxShadow: 'none !important',
            height: 44,
            minHeight: 44,
            width: 300,
          }),
          dropdownIndicator: (baseStyles) => ({
            ...baseStyles,
            color: '#CECACA !important',
            padding: 0,
          }),
          groupHeading: (baseStyles) => ({
            ...baseStyles,
            color: '#686464',
            fontSize: 12,
            fontWeight: '600',
            padding: '6px 12px 8px 12px',
            textTransform: 'capitalize',
          }),
          indicatorsContainer: (baseStyles) => ({
            ...baseStyles,
            height: 44,
            paddingLeft: 16,
            paddingRight: 16,
          }),
          indicatorSeparator: () => ({}),
          menu: (baseStyles) => ({ ...baseStyles, width: 300 }),
          option: (baseStyles, state) => ({
            ...baseStyles,
            backgroundColor:
              state.isFocused || state.isSelected
                ? '#F3F2F1 !important'
                : '#FFFFFF !important',
            color: '#1B1818',
            padding: '12px 16px',
          }),
          placeholder: (baseStyles) => ({ ...baseStyles, marginBottom: 2 }),
          singleValue: (baseStyles) => ({
            ...baseStyles,
            color: '#353131',
            marginBottom: 2,
            maxWidth: 300,
          }),
          valueContainer: (baseStyles) => ({
            ...baseStyles,
            display: 'flex',
            height: 44,
            input: { position: 'absolute' },
            minHeight: 44,
            padding: '10px 16px',
          }),
        }}
      />
    </div>
  )
}

const DropdownIndicator = (
  props: DropdownIndicatorProps<ComparePositionSelectOption, false>
) => {
  return (
    <components.DropdownIndicator {...props}>
      <CaretDown aria-hidden className="h-4 text-gray-200 w-4" weight="bold" />
    </components.DropdownIndicator>
  )
}
