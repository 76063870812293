import { AvatarButtonGroup } from 'src/design-system'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import cn from 'classnames'
import { avatarGroupProps } from 'app/packs/src/utils/user-helpers'
import { Checkin } from 'store/modules/checkins'
import { CheckinProgressIndicator } from 'components/checkin-progress-indicator'
import { format } from 'date-fns'
import { OverviewVm } from './overview-vm'
import styles from './styles.module.scss'

type OverviewProps = {
  checkin: Checkin
}

export const Overview = observer((props: OverviewProps) => {
  const { checkin } = props

  const vm = React.useMemo(() => {
    return new OverviewVm(checkin)
  }, [checkin])

  return (
    <div className="flex flex-col gap-4 items-center">
      <AvatarButtonGroup
        avatarClassName="border-white border-px border-solid"
        avatars={avatarGroupProps([checkin.author])}
        className="pointer-events-none"
        size="lg"
      />
      {checkin.summaryStatus && (
        <>
          <h2 className="mb-0 text-2xl text-center text-gray-900">
            {vm.summaryUserText}
            <span className={cn(styles[checkin.summaryStatus])}>
              {vm.prettifiedSummaryStatus}
            </span>{' '}
            {checkin.positionName}
          </h2>
          {checkin.summaryFinalLevelTotal &&
            checkin.summaryRequiredLevelTotal && (
              <CheckinProgressIndicator
                className="h-2 max-w-[300px]"
                max={Math.max(
                  checkin.summaryFinalLevelTotal,
                  checkin.summaryRequiredLevelTotal
                )}
                status={checkin.summaryStatus}
                value={checkin.summaryFinalLevelTotal}
              />
            )}
        </>
      )}
      <div className="flex flex-wrap gap-1 items-center justify-center">
        <p className="mb-0 text-gray-700 text-sm">
          <span className="font-semibold text-gray-900">
            {vm.percentageDifferenceText}
          </span>
          {checkin.finalisedAt &&
            ` on ${format(checkin.finalisedAt, 'dd MMMM yyyy')}`}
        </p>
        {checkin.assessor && (
          <p className="mb-0 text-gray-700 text-sm">
            {' with '}
            <span className="font-semibold mb-0 text-gray-900">
              {checkin.assessor.fullName}
            </span>
          </p>
        )}
      </div>
    </div>
  )
})
