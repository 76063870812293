import { AnimatePresence, motion } from 'framer-motion'
import { AvatarButtonGroup } from 'src/design-system'
import { CaretDown, CaretUp } from '@phosphor-icons/react'
import { observer } from 'mobx-react-lite'
import * as Accordion from '@radix-ui/react-accordion'
import * as React from 'react'
import { avatarGroupProps } from 'app/packs/src/utils/user-helpers'
import { Checkin } from 'store/modules/checkins'
import { CheckinSkill } from 'store/modules/checkin-skills'
import {
  CheckinStatus,
  CheckinStatusLabel,
} from 'components/checkin-status-label'
import { HtmlContent } from 'components/atoms/editor/html-content'
import { levelStatus } from 'app/packs/src/utils/checkin-helpers'
import { SensitiveContent } from 'components/sensitive-content'
import { User } from 'store/modules/users'
import cn from 'classnames'

export type CheckinSkillCardNotesProps = {
  checkin: Checkin
  checkinSkill: CheckinSkill
}

export const CheckinSkillCardNotes: React.VFC<CheckinSkillCardNotesProps> =
  observer((props) => {
    const { checkin, checkinSkill } = props

    return (
      <div className="flex flex-col gap-y-7 relative">
        <div className="absolute border-0 border-gray-100 border-l border-solid h-full ml-2.5 mt-1"></div>
        <CheckinSkillCardNote
          description={checkinSkill.description}
          skipped={checkinSkill.userSkipped}
          status={levelStatus(checkinSkill.level, checkinSkill.requiredLevel)}
          title={checkin.author.fullName}
          users={[checkin.author]}
        />
        <CheckinSkillCardNote
          description={checkinSkill.assessorDescription}
          skipped={checkinSkill.assessorSkipped}
          status={levelStatus(
            checkinSkill.assessorLevel,
            checkinSkill.requiredLevel
          )}
          title={checkin.assessor?.fullName || 'Assessor'}
          users={checkin.assessor ? [checkin.assessor] : []}
        />
        <CheckinSkillCardNote
          description={checkinSkill.finalDescription}
          skipped={checkinSkill.finalSkipped}
          status={levelStatus(
            checkinSkill.finalLevel,
            checkinSkill.requiredLevel
          )}
          title="Joint review"
          users={checkin.users}
        />
      </div>
    )
  })

export type CheckinSkillCardNoteProps = {
  description: string | null
  title: string
  skipped: boolean | null
  status: CheckinStatus
  users: User[]
}

export const CheckinSkillCardNote: React.VFC<CheckinSkillCardNoteProps> =
  observer((props) => {
    const {
      description: defaultDescription,
      skipped,
      status,
      title,
      users,
    } = props

    const [open, setOpen] = React.useState(true)
    const onClick = React.useCallback(() => setOpen(!open), [open])

    const Icon = open ? CaretUp : CaretDown

    let description = defaultDescription
    if (!description) description = 'No answer'
    if (skipped) description = 'Skipped'

    return (
      <AnimatePresence>
        <Accordion.Root type="single">
          <Accordion.Item value="item-1">
            <Accordion.Header>
              <Accordion.Trigger asChild>
                <div
                  className="flex items-center justify-between w-full text-sm font-normal appearance-none"
                  onClick={onClick}
                >
                  <div className="flex flex-row items-center gap-x-3">
                    <AvatarButtonGroup
                      avatarClassName="border-white border-px border-solid"
                      avatars={avatarGroupProps(users, { stateTooltip: true })}
                      size="xs"
                    />
                    <div>{title}</div>
                    <CheckinStatusLabel status={status} />
                  </div>
                  <Icon className="h-3.5 text-gray-200 w-3.5" weight="bold" />
                </div>
              </Accordion.Trigger>
            </Accordion.Header>
            {open && (
              <motion.div
                animate={{ height: 'auto', opacity: 1 }}
                exit={{ height: 0, opacity: 0 }}
                initial={{ height: 0, opacity: 0 }}
                transition={{ ease: 'easeInOut', duration: 0.2 }}
              >
                <Accordion.Content className="ml-2.5" forceMount>
                  <SensitiveContent>
                    <div className="flex flex-row gap-x-2">
                      <HtmlContent
                        className={cn(
                          'ml-4 mt-4',
                          (!defaultDescription || skipped) && 'opacity-50'
                        )}
                      >
                        {description}
                      </HtmlContent>
                    </div>
                  </SensitiveContent>
                </Accordion.Content>
              </motion.div>
            )}
          </Accordion.Item>
        </Accordion.Root>
      </AnimatePresence>
    )
  })
