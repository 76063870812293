import { observer } from 'mobx-react-lite'
import { Tag } from 'src/design-system'
import * as React from 'react'
import { CheckinSkill } from 'store/modules/checkin-skills'
import { CheckinStatusLabel } from 'components/checkin-status-label'
import { levelStatus } from 'app/packs/src/utils/checkin-helpers'
import { FocusSkillStar } from 'components/focus-skill-star'
import { useStore } from 'store/context'

type StrengthsAndAreasToImproveCardProps = {
  authorId: string
  checkinSkills: CheckinSkill[]
  positionName: string | null
  title: string
}

export const StrengthsAndAreasToImproveCard = observer(
  (props: StrengthsAndAreasToImproveCardProps) => {
    const { checkinSkills, positionName, title, authorId } = props
    const store = useStore()

    return (
      <div>
        <h3 className="font-bold mb-1 text-gray-900 text-xl">{title}</h3>
        {positionName && (
          <p className="mb-4 text-gray-600 text-sm">vs {positionName}</p>
        )}
        <div className="flex flex-col gap-5">
          {checkinSkills.map((checkinSkill) => (
            <div key={checkinSkill.id}>
              <div className="flex gap-2 items-center mb-2">
                <p className="mb-0">{checkinSkill.skill.name}</p>
                <FocusSkillStar
                  editable={authorId === store.currentUser?.id}
                  source="checkin-summary-strengths-and-areas-to-improve-card"
                  userSkill={store.userSkills.forSkillAndUser(
                    checkinSkill.skill.id,
                    authorId
                  )}
                />
              </div>
              <div className="flex gap-2">
                {checkinSkill.finalLevel !== null && (
                  <Tag variant="paper">L{checkinSkill.finalLevel}</Tag>
                )}
                <CheckinStatusLabel
                  status={levelStatus(
                    checkinSkill.finalLevel,
                    checkinSkill.requiredLevel
                  )}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    )
  }
)
