import { ArrowRight, Plus } from '@phosphor-icons/react'
import { Link } from 'src/design-system'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { ActionsEmptyState } from 'components/actions-empty-state'
import { ActionsTable } from 'components/actions-table'
import { Checkin } from 'store/modules/checkins'
import { CHECKIN_SUMMARY_PAGE_SOURCE } from '../utils'
import { CreateFormModalButton } from 'components/create-form-modal'
import { store } from 'store/index'

export type ActionsSectionProps = {
  checkin: Checkin
  editable: boolean
}

export const ActionsSection: React.VFC<ActionsSectionProps> = observer(
  (props) => {
    const { checkin, editable } = props

    const createdFromId = checkin.id
    const createdFromType = 'Checkin'

    const actions = store.actions.forCreatedFromAndUser(
      createdFromId,
      createdFromType,
      checkin.author.id
    )

    const actionsUrl = checkin.author.isCurrentUser
      ? checkin.author.actionsUrl
      : checkin.author.profileUrl

    const srText = checkin.author.isCurrentUser
      ? "Action's page"
      : "User's profile"

    if (actions.length === 0 && !editable) return null

    return (
      <div className="flex flex-col w-full">
        {actions.length > 0 && (
          <>
            <div className="flex items-center justify-between pb-4">
              <h3 className="text-gray-900 text-xl">Actions</h3>
              <div className="flex gap-2 items-center">
                {editable && (
                  <CreateFormModalButton
                    className="bg-white flex items-center justify-center p-0"
                    createdFromId={createdFromId}
                    createdFromType={createdFromType}
                    label={
                      <Plus className="h-4 text-theme-50 w-4" weight="bold" />
                    }
                    source={CHECKIN_SUMMARY_PAGE_SOURCE}
                    tabs={['action']}
                    title="Add action"
                    user={checkin.author}
                  />
                )}
                <Link
                  className="flex items-center p-0"
                  href={actionsUrl}
                  underline={false}
                  data-turbo-frame="content"
                  data-turbo-action="advance"
                >
                  <ArrowRight className="h-4 text-gray-200 w-4" weight="bold" />
                  <span className="sr-only">{srText}</span>
                </Link>
              </div>
            </div>
            <div className="border border-gray-100 pb-2 pt-6 px-6 rounded-lg">
              <ActionsTable
                actions={actions}
                editable={editable}
                focusSkillIds={store.userSkills.focusedSkillIdsForUser(
                  checkin.author.id
                )}
                source={CHECKIN_SUMMARY_PAGE_SOURCE}
              />
            </div>
          </>
        )}
        {actions.length === 0 && (
          <ActionsEmptyState
            className="w-full"
            createdFromId={createdFromId}
            createdFromType={createdFromType}
            source={CHECKIN_SUMMARY_PAGE_SOURCE}
            user={checkin.author}
            variant="sidebar"
          />
        )}
      </div>
    )
  }
)
