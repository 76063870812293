import { Checkin } from 'store/modules/checkins'
import { store } from 'store/index'
import { User } from 'store/modules/users'

export class CheckinSummaryPageVm {
  constructor(private authorId: string, private checkinId: string) {}

  get nextCheckin() {
    const checkins = this.checkins
    const index = checkins.findIndex((checkin) => checkin.id === this.checkinId)
    return checkins[index - 1]
  }

  get previousCheckin() {
    const checkins = this.checkins
    const index = checkins.findIndex((checkin) => checkin.id === this.checkinId)
    return checkins[index + 1]
  }

  dateFrom(checkin: Checkin) {
    return (
      this.previousFinalisedUserCheckin(checkin)?.createdAt ||
      checkin.author.createdAt
    )
  }

  dateTo(checkin: Checkin) {
    return checkin.finalisedAt || new Date()
  }

  editable(checkin: Checkin, user: User) {
    return checkin.userIds.includes(user.id) && checkin.isLastFinalisedForAuthor
  }

  private get checkins() {
    return store.checkins.finalisedSortedByFinalisedAtDescForAuthor(
      this.authorId
    )
  }

  private previousFinalisedUserCheckin(checkin: Checkin) {
    return store.checkins.previousFinalisedUserCheckin(checkin)
  }
}
