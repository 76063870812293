import { Button } from 'src/design-system'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import cn from 'classnames'
import pluralize from 'pluralize'
import { useStore } from 'store/context'

export type UpdatesDrawerButtonProps = {
  children?: React.ReactNode
  fromDatetime?: string
  skillId: string
  source?: string
  // TODO: To remove once we have updated the check-in stages UI (show always be a button after)
  toDatetime?: string
  userId: string
  variant?: 'button' | 'text'
}

export const UpdatesDrawerButton = observer(
  (props: UpdatesDrawerButtonProps) => {
    const {
      children,
      fromDatetime,
      skillId,
      source,
      toDatetime,
      userId,
      variant = 'text',
    } = props

    const { activities, skills } = useStore()

    const onClick = () => {
      const event = new CustomEvent('updatesdrawer:open', {
        detail: {
          id: skillId,
          name: skills.byId(skillId)?.name,
          source,
          userId: userId,
        },
      })

      document.dispatchEvent(event)
    }

    const updateCount = activities.filteredActivities({
      fromDatetime,
      skillId,
      toDatetime,
      userId,
    }).length

    if (variant === 'text') {
      return (
        <>
          {updateCount === 0 ? (
            <p className="inline mb-0 px-1 text-gray-500 text-xs">No updates</p>
          ) : (
            <button
              className="px-1 text-theme-40 text-xs"
              onClick={onClick}
              type="button"
            >
              {children ||
                `${updateCount} ${pluralize('updates', updateCount)}`}
            </button>
          )}
        </>
      )
    }

    return (
      <Button
        className={cn(
          'py-1.5 px-3 text-sm bg-theme-40 text-white',
          updateCount === 0 && 'opacity-50'
        )}
        colourVariant="theme"
        disabled={updateCount === 0}
        onClick={onClick}
        type="button"
      >
        {`${updateCount} ${pluralize('updates', updateCount)} ->`}
      </Button>
    )
  }
)
