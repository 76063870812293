import {
  CalendarBlank,
  Circle,
  Clipboard,
  MinusCircle,
  UsersThree,
} from '@phosphor-icons/react'
import * as React from 'react'
import { SearchFilter } from 'components/search-filter'
import { Select } from 'components/atoms/select'
import { trackEvent } from 'app/packs/src/services/event-tracker'
import { useCheckinsTable } from './context/checkins-table'
import { useStore } from 'store/context'
import { ManagerUserSelect } from 'components/manager-user-select'
import { Button } from 'src/design-system'
import { ManagerSelectValue } from '../../../components/manager-user-select/manager-user-select.types'

export const Filters = () => {
  const {
    data,
    globalFilter,
    setGlobalFilter,
    table,
    columnFilters,
    columnFiltersObject,
  } = useCheckinsTable()
  const { nonNullCurrentUser: currentUser } = useStore()

  const showManagerAdminFilters = currentUser.isAdmin || currentUser.isManager
  const showAdminFilters = currentUser.isAdmin
  const showManagerFilters = currentUser.isManager
  const trackFilterChanged = (filterName: string, value: string | string[]) => {
    trackEvent('$track_checkin_insights_filter_changed', {
      [filterName]: value,
    })
  }
  const filterValue = (columnId: string) =>
    table.getState().columnFilters.find((column) => column.id === columnId)
      ?.value

  const showClearFiltersButton = React.useMemo(() => {
    return columnFilters.length > 0 || (globalFilter && globalFilter !== '')
  }, [columnFilters, globalFilter])
  const onClearFilters = () => {
    table.resetColumnFilters()
    table.resetGlobalFilter()
    table.resetSorting()
  }

  return (
    <div className="flex gap-x-3 mb-5 overflow-x-auto pl-4">
      {showManagerAdminFilters && (
        <SearchFilter
          name="checkin-insights-search"
          onChange={(value) => setGlobalFilter(String(value))}
          value={globalFilter ?? ''}
        />
      )}
      <Select
        isMulti={false}
        onChange={(value: string) => {
          trackFilterChanged('started', value)
          table.getColumn('started')?.setFilterValue(value)
        }}
        options={data.timeOptions}
        triggerContent={<TimeTriggerContent />}
        value={filterValue('started') as string | undefined}
      />
      {showAdminFilters && (
        <Select
          isMulti
          onChange={(values: string[]) => {
            trackFilterChanged('team', values)
            table.getColumn('team')?.setFilterValue(values)
          }}
          options={data.teamOptions}
          triggerContent={<TeamTriggerContent />}
          value={filterValue('team') as string[] | undefined}
        />
      )}
      {showManagerFilters && (
        <ManagerUserSelect
          showYou
          showEverybody={currentUser.isAdmin}
          onChange={(value: string) =>
            table.getColumn('name')?.setFilterValue(value)
          }
          user={currentUser}
          value={columnFiltersObject.name as ManagerSelectValue | undefined}
        />
      )}
      {showManagerAdminFilters && (
        <Select
          isMulti={false}
          onChange={(value: string) => {
            trackFilterChanged('checkinType', value)

            table
              .getColumn('checkinType')
              ?.setFilterValue(value === 'all' ? null : value)
          }}
          options={data.typeOptions}
          triggerContent={<TypeTriggerContent />}
          value={filterValue('checkinType') as string | undefined}
        />
      )}
      <Select
        isMulti
        onChange={(values: string[]) => {
          trackFilterChanged('stage', values.length > 0 ? values : 'all')
          table.getColumn('progress')?.setFilterValue(values)
        }}
        options={data.stageOptions}
        triggerContent={<StageTriggerContent />}
        value={filterValue('progress') as string[] | undefined}
      />
      <Select
        isMulti
        onChange={(values: string[]) => {
          trackFilterChanged('finalLevel', values.length > 0 ? values : 'all')

          table.getColumn('finalLevel')?.setFilterValue(values)
        }}
        options={data.levelOptions}
        triggerContent={<LevelTriggerContent />}
        value={filterValue('finalLevel') as string[] | undefined}
      />
      {showClearFiltersButton && (
        <Button className="h-[34px]" onClick={onClearFilters} variant="outline">
          <MinusCircle
            aria-hidden
            className="h-4 text-theme-40 w-4"
            weight="bold"
          />
          Clear filters
        </Button>
      )}
    </div>
  )
}

const LevelTriggerContent: React.VFC = () => {
  return (
    <div className="flex gap-1 items-center">
      <Circle aria-hidden className="h-4 text-gray-600 w-4" weight="bold" />
      All levels
    </div>
  )
}

const StageTriggerContent: React.VFC = () => {
  return (
    <div className="flex gap-1 items-center">
      <Clipboard aria-hidden className="h-4 text-gray-600 w-4" weight="bold" />
      All stages
    </div>
  )
}

const TeamTriggerContent: React.VFC = () => {
  return (
    <div className="flex gap-1 items-center">
      <UsersThree aria-hidden className="h-4 text-gray-600 w-4" weight="bold" />
      All teams
    </div>
  )
}

const TimeTriggerContent: React.VFC = () => {
  return (
    <div className="flex gap-1 items-center">
      <CalendarBlank
        aria-hidden
        className="h-4 text-gray-600 w-4"
        weight="bold"
      />
      All time
    </div>
  )
}

const TypeTriggerContent: React.VFC = () => {
  return (
    <div className="flex gap-1 items-center">
      <Clipboard aria-hidden className="h-4 text-gray-600 w-4" weight="bold" />
      All Check-ins
    </div>
  )
}
