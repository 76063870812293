import { DownloadSimple } from '@phosphor-icons/react'
import * as React from 'react'
import { Button } from 'src/design-system'
import { downloadLink } from '../../utils/file-helpers'
import { trackEvent } from '../../services/event-tracker'

export type CheckinsDownloadButtonProps = {
  columnFilters?: Record<string, string>
  csvData: () => string
}

export const CheckinsDownloadButton = (props: CheckinsDownloadButtonProps) => {
  const { columnFilters, csvData } = props

  const onDownloadClick = () => {
    trackEvent('$track_download_checkins_clicked', { filters: columnFilters })
    const csvContent = `data:text/csv;charset=utf-8,${csvData()}`
    const csvFilename = `checkins-${new Date().toLocaleDateString()}.csv`
    downloadLink(csvContent, csvFilename)
  }

  return (
    <Button
      className="flex flex-row items-center"
      data-element-id="download-checkin-insights-view"
      onClick={onDownloadClick}
      variant="outline"
    >
      <DownloadSimple aria-hidden size={16} weight="bold" />
      Download view
    </Button>
  )
}
