import { Checkin } from 'store/modules/checkins'
import { CheckinSkill } from 'store/modules/checkin-skills'

export class CheckinSkillsSectionVm {
  constructor(private checkin: Checkin) {}

  get checkinSkillsGroupedByCategory() {
    const checkinSkills = this.checkin.selectedCheckinSkills
    const skillIds = checkinSkills.map((checkinSkill) => checkinSkill.skill.id)
    const key = 'categoryName'

    const frameworksSkills = this.checkin.author.frameworksSkills.filter(
      (frameworksSkill) =>
        frameworksSkill.skill && skillIds.includes(frameworksSkill.skill.id)
    )

    return Array.from(
      checkinSkills.reduce((map, checkinSkill) => {
        const categoryName = checkinSkill[key]

        if (!map.has(categoryName)) {
          const categorySkills = checkinSkills.filter(
            (i) => i[key] === checkinSkill[key]
          )

          const sortedCategorySkills = categorySkills.sort((a, b) => {
            const frameworksSkillA = frameworksSkills.find(
              (frameworksSkill) => frameworksSkill.skill?.id === a.skill.id
            )

            const frameworksSkillB = frameworksSkills.find(
              (frameworksSkill) => frameworksSkill.skill?.id === b.skill.id
            )

            if (!frameworksSkillA || !frameworksSkillB) return 1

            return frameworksSkillA.listPosition - frameworksSkillB.listPosition
          })

          map.set(categoryName, sortedCategorySkills)
        }

        return map
      }, new Map<CheckinSkill['categoryName'] | null, CheckinSkill[]>())
    )
  }
}
