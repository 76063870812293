import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { Checkin } from 'store/modules/checkins'
import { CheckinSkillCard } from './checkin-skill-card'
import { CheckinSkillsSectionVm } from './checkin-skills-section-vm'

type CheckinSkillsSectionProps = {
  checkin: Checkin
  dateFrom: Date
  dateTo: Date
  editable: boolean
}

export const CheckinSkillsSection = observer(
  (props: CheckinSkillsSectionProps) => {
    const { checkin, dateFrom, dateTo, editable } = props

    const vm = React.useMemo(() => {
      return new CheckinSkillsSectionVm(checkin)
    }, [checkin])

    return (
      <>
        {vm.checkinSkillsGroupedByCategory.map(
          ([categoryName, checkinSkills]) => (
            <div key={categoryName}>
              <h3 className="font-bold mb-4 text-gray-900">{categoryName}</h3>
              <div className="flex flex-col gap-y-4">
                {checkinSkills.map((checkinSkill) => (
                  <CheckinSkillCard
                    checkin={checkin}
                    checkinSkill={checkinSkill}
                    dateFrom={dateFrom}
                    dateTo={dateTo}
                    editable={editable}
                    key={checkinSkill.id}
                  />
                ))}
              </div>
            </div>
          )
        )}
      </>
    )
  }
)
