import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { Checkin } from 'store/modules/checkins'
import { DetailsSectionVm } from './details-section-vm'
import { Editor } from 'components/atoms/editor'
import { HtmlContent } from 'components/atoms/editor/html-content'
import { User } from 'store/modules/users'

export type DetailsSectionProps = {
  checkin: Checkin
  editable: boolean
  user: User
}

export const DetailsSection: React.VFC<DetailsSectionProps> = observer(
  (props) => {
    const { checkin, editable, user } = props

    const [content, setContent] = React.useState('')

    const vm = React.useMemo(() => {
      return new DetailsSectionVm(checkin, user)
    }, [checkin, user])

    return (
      <div className="flex flex-col gap-3 w-full" data-recording-sensitive>
        <h3 className="font-bold mb-2 text-gray-900 text-xl">
          Check-in details
        </h3>
        <div>
          <p className="font-bold text-gray-900 text-base">Title</p>
          <p className="text-gray-900 text-sm">
            {checkin.titleWithFallback(user.id)}
          </p>
        </div>
        <div>
          <p className="font-bold text-gray-900 text-base">Description</p>
          <HtmlContent className="mb-0 mt-3 text-gray-900">
            {vm.description}
          </HtmlContent>
        </div>
        <div>
          <p className="font-bold text-gray-900 text-base">Summary</p>
          {editable ? (
            <Editor
              className="border border-gray-200"
              fixHeight={vm.fixedHeight}
              initialContent={vm.summaryContent}
              onBlur={() => vm.onBlur(content)}
              onChange={(html) => setContent(html)}
              placeholder={vm.placeholderText}
              rows={4}
            />
          ) : (
            <HtmlContent>{vm.readonlyContent}</HtmlContent>
          )}
        </div>
      </div>
    )
  }
)
