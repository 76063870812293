import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { Checkin } from 'store/modules/checkins'
import { StrengthsAndAreasToImproveCard } from './strengths-and-areas-to-improve-card'
import { StrengthsAndAreasToImproveSectionVm } from './strengths-and-areas-to-improve-section-vm'

type StrengthsAndAreasToImproveSectionProps = {
  checkin: Checkin
}

export const StrengthsAndAreasToImproveSection = observer(
  (props: StrengthsAndAreasToImproveSectionProps) => {
    const { checkin } = props

    const vm = React.useMemo(() => {
      return new StrengthsAndAreasToImproveSectionVm(checkin)
    }, [checkin])

    return (
      <div className="gap-20 md:gap-36 grid grid-cols-1 md:grid-cols-2">
        <StrengthsAndAreasToImproveCard
          authorId={checkin.author.id}
          checkinSkills={vm.strengthCheckinSkills}
          positionName={checkin.positionName}
          title="Strengths"
        />
        <StrengthsAndAreasToImproveCard
          authorId={checkin.author.id}
          checkinSkills={vm.improvementCheckinSkills}
          positionName={checkin.positionName}
          title="Areas to improve"
        />
      </div>
    )
  }
)
