import { Checkin } from 'store/modules/checkins'
import { CheckinSkill } from 'store/modules/checkin-skills'
import { CheckinSkillOutcome } from './utils'
import { store } from 'store/index'

export class CheckinSkillCardVm {
  constructor(private checkin: Checkin, private checkinSkill: CheckinSkill) {}

  get allSkipped() {
    if (!this.checkinSkill.assessorSkipped) return false
    if (!this.checkinSkill.finalSkipped) return false

    return this.checkinSkill.userSkipped
  }

  get previousCheckinSkillStatus() {
    if (!this.previousFinalisedUserCheckin) return

    const previousCheckinSkill =
      this.previousFinalisedUserCheckin.checkinSkills.find(
        (i) => i.associatedSkill.id === this.skillId
      )

    if (!previousCheckinSkill) return
    if (previousCheckinSkill.status === this.checkinSkill.status) return

    return previousCheckinSkill.status
  }

  get skillId() {
    return this.checkinSkill.associatedSkill.id
  }

  get skillLevel() {
    return this.checkinSkill.associatedSkillLevel
  }

  get skillLevelOutcomes(): CheckinSkillOutcome[] {
    return this.checkinSkill.associatedSkillLevelOutcomes
  }

  get skillName() {
    return this.checkinSkill.associatedSkill.name
  }

  get skillVariantId() {
    return this.checkinSkill.associatedSkill.skillVariantId || undefined
  }

  private get previousFinalisedUserCheckin() {
    return store.checkins.previousFinalisedUserCheckin(this.checkin)
  }
}
