import { CheckinSkillLevelLabel } from 'components/checkin-skill-level-label'
import { CheckinStatusLabel } from 'components/checkin-status-label'
import { CheckinSummaryPage } from 'pages/checkin-summary-page'
import { forRails } from 'components/for-rails'
import { registerComponents } from '../src/utils/react/registry'
import { UpdatesDrawer } from 'components/updates-drawer'
import { UpdatesDrawerButton } from 'components/updates-drawer-button'
import { CheckinsPage } from 'pages/checkins-page'

registerComponents({
  CheckinsPage: forRails(CheckinsPage),
  CheckinSkillLevelLabel: forRails(CheckinSkillLevelLabel),
  CheckinStatusLabel: forRails(CheckinStatusLabel),
  CheckinSummaryPage: forRails(CheckinSummaryPage),
  UpdatesDrawer: forRails(UpdatesDrawer),
  UpdatesDrawerButton: forRails(UpdatesDrawerButton),
})
