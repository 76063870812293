import { Row } from '@tanstack/react-table'
import { Checkin } from 'store/modules/checkins'
import { CheckinTableRow } from '../data'

const progressOrder: Checkin['status'][] = [
  'not_started',
  'in_progress',
  'pending_assessor',
  'assessor_in_progress',
  'pending_review',
  'review_in_progress',
  'finalised',
]

export const sortByProgress = (
  aRow: Row<CheckinTableRow>,
  bRow: Row<CheckinTableRow>
) => {
  const a = aRow.original
  const b = bRow.original

  if (!a.status) return 1
  if (!b.status) return -1

  const aProgress = progressOrder.indexOf(a.status)
  const bProgress = progressOrder.indexOf(b.status)

  return aProgress - bProgress
}
