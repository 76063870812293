import { ArrowRight } from '@phosphor-icons/react'
import { Card } from 'src/design-system'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { Checkin } from 'store/modules/checkins'
import { CHECKIN_SUMMARY_SKILL_CARD_SOURCE } from './utils'
import { CheckinSkill } from 'store/modules/checkin-skills'
import { CheckinSkillCardNotes } from './checkin-skill-card-notes'
import { CheckinSkillCardVm } from './checkin-skill-card-vm'
import { CheckinStatusLabel } from 'components/checkin-status-label'
import { FocusSkillStar } from 'components/focus-skill-star'
import { HtmlContent } from 'components/atoms/editor/html-content'
import { SkillLevelOutcomesSection } from 'components/skill-level-outcomes-section'
import { SkillModalButton } from 'components/skill-modal/skill-modal-button'
import { store } from 'store/index'
import { UpdatesDrawerButton } from 'components/updates-drawer-button'

export type CheckinSkillCardProps = {
  checkin: Checkin
  checkinSkill: CheckinSkill
  dateFrom: Date
  dateTo: Date
  editable: boolean
}

export const CheckinSkillCard: React.VFC<CheckinSkillCardProps> = observer(
  (props) => {
    const { checkin, checkinSkill, dateFrom, dateTo, editable } = props

    const vm = React.useMemo(() => {
      return new CheckinSkillCardVm(checkin, checkinSkill)
    }, [checkin, checkinSkill])

    const allSkipped = vm.allSkipped
    const previousCheckinSkillStatus = vm.previousCheckinSkillStatus
    const skillLevel = vm.skillLevel
    const skillLevelOutcomes = vm.skillLevelOutcomes

    const userSkill = store.userSkills.forSkillAndUser(
      vm.skillId,
      checkin.author.id
    )

    return (
      <Card className="flex flex-col m-0 p-0">
        <div className="flex flex-col gap-y-3 p-6">
          <div className="flex flex-row justify-between text-base w-full">
            <div className="flex items-center">
              <SkillModalButton
                className="font-bold p-0 text-gray-900 cursor-pointer"
                skillId={vm.skillId}
                skillVariantId={vm.skillVariantId}
                source="checkin_summary_skill_card"
              >
                {vm.skillName}
              </SkillModalButton>
              <div className="ml-1 text-gray-600">
                L{checkinSkill.requiredLevel}
              </div>
              {userSkill && (
                <FocusSkillStar
                  className={
                    !userSkill.focused && !checkin.author.isCurrentUser
                      ? 'hidden ml-2'
                      : 'ml-2'
                  }
                  editable={editable && checkin.author.isCurrentUser}
                  size={14}
                  source={CHECKIN_SUMMARY_SKILL_CARD_SOURCE}
                  userSkill={userSkill}
                />
              )}
            </div>
            <div className="flex gap-2 items-center">
              {previousCheckinSkillStatus && (
                <>
                  <CheckinStatusLabel status={previousCheckinSkillStatus} />
                  <ArrowRight className="h-4 text-gray-600 w-4" weight="bold" />
                </>
              )}
              <CheckinStatusLabel status={checkinSkill.status} />
            </div>
          </div>
          {skillLevel && (
            <HtmlContent className="w-full">{skillLevel.name}</HtmlContent>
          )}
          {skillLevelOutcomes.length > 0 && (
            <SkillLevelOutcomesSection outcomes={skillLevelOutcomes} />
          )}
        </div>
        <div className="flex flex-col gap-4 p-6 w-full">
          <div className="flex justify-between">
            <h5 className="font-bold text-base text-gray-900">
              {allSkipped ? 'Skipped' : 'Assessment'}
            </h5>
            <UpdatesDrawerButton
              fromDatetime={dateFrom.toISOString()}
              skillId={vm.skillId}
              source={CHECKIN_SUMMARY_SKILL_CARD_SOURCE}
              toDatetime={dateTo.toISOString()}
              userId={checkin.author.id}
              variant="button"
            />
          </div>
          {!allSkipped && (
            <CheckinSkillCardNotes
              checkin={checkin}
              checkinSkill={checkinSkill}
            />
          )}
        </div>
      </Card>
    )
  }
)
