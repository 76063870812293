export const checkinLevels = [
  'exceeding',
  'meeting',
  'working_towards',
  'not_checked_in',
] as const

export type CheckinLevel = typeof checkinLevels[number]

export const CHECKIN_LEVEL_LABELS: Record<CheckinLevel, string> = {
  exceeding: 'Exceeding',
  meeting: 'Meeting',
  not_checked_in: 'Not Checked-In',
  working_towards: 'Working Towards',
}

export type CheckinStage =
  | 'not_created'
  | 'step_1'
  | 'step_2'
  | 'step_3'
  | 'step_4'

export const CHECKIN_STAGE_LABELS: Record<CheckinStage, string> = {
  not_created: 'Not started',
  step_1: 'Step 1: with employee',
  step_2: 'Step 2: with assessor',
  step_3: 'Step 3: joint review',
  step_4: 'Step 4: completed',
}

export const checkinTypes = ['all', 'latest'] as const

export type CheckinType = typeof checkinTypes[number]

export const CHECKIN_TYPE_LABELS: Record<CheckinType, string> = {
  all: 'All Check-ins',
  latest: 'Latest Check-ins',
}

export const timePeriods = [
  'all time',
  '30 days',
  '3 months',
  '6 months',
  '12 months',
] as const

export type TimePeriod = typeof timePeriods[number]

export const TIME_PERIOD_LABELS: Record<TimePeriod, string> = {
  'all time': 'All time',
  '30 days': '30 days',
  '3 months': '3 months',
  '6 months': '6 months',
  '12 months': '12 months',
}

export type tabIdType = 'org' | 'reports' | 'assessing' | 'you'
