import { Link } from 'src/design-system'
import { CellContext } from '@tanstack/react-table'
import { CheckinProgressBar } from 'components/checkin-progress-bar'
import { StartCheckinButton } from 'components/start-checkin-button'
import * as React from 'react'
import { store } from 'store/index'
import { CheckinTableRow } from '../data'

export const ProgressCell = (data: CellContext<CheckinTableRow, unknown>) => {
  const row = data.row.original
  const author = store.users.byId(row.authorId)
  const checkin = store.checkins.byId(row.id)
  if (!checkin || !author) return null

  if (!row.stage)
    return <StartCheckinButton user={author} source="checkins_page" />

  return (
    <Link href={checkin.path} underline={false} className="block">
      <CheckinProgressBar checkin={checkin} minimal />
    </Link>
  )
}
