import { CellContext } from '@tanstack/react-table'
import cn from 'classnames'
import { CheckinStatusLabel } from 'components/checkin-status-label'
import * as React from 'react'
import { store } from 'store/index'
import { CheckinTableRow } from '../data'

export const FinalLevelCell = (data: CellContext<CheckinTableRow, unknown>) => {
  const row = data.row.original
  const checkin = store.checkins.byId(row.id)

  const summaryStatus = row.finalLevel

  const summaryPercentageDifference = checkin
    ? checkin.summaryPercentageDifference
    : null

  let differenceText = ''
  if (summaryStatus === 'exceeding') differenceText = ' above'
  if (summaryStatus === 'working_towards') differenceText = ' away'

  return (
    <div
      className={cn(
        'flex gap-2 h-full items-center w-max',
        row.finalLevel == 'not_checked_in' && 'opacity-50'
      )}
    >
      <CheckinStatusLabel status={row.finalLevel} />
      {differenceText !== '' && (
        <p className="mb-0 text-xs">
          {summaryPercentageDifference}%{differenceText}
        </p>
      )}
    </div>
  )
}
