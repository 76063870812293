import * as React from 'react'
import { Sparkle } from '@phosphor-icons/react'
import { useCheckinsTable } from '../table/context/checkins-table'
import { ColumnFiltersState } from '@tanstack/react-table'
import { WithAnalytics } from 'components/with-analytics'
import { observer } from 'mobx-react-lite'
import cn from 'classnames'
import equal from 'fast-deep-equal'
import { filters } from './filters'

export const Assistant = observer(() => {
  const { table, columnFilters, data } = useCheckinsTable()

  const reset = () => {
    table.resetColumnFilters()
    table.resetGlobalFilter()
    table.resetSorting()
  }
  const setFilters = (filters: ColumnFiltersState) => {
    table.setColumnFilters(filters)
  }

  const activeFilter = React.useMemo<
    (typeof filters)[number]['id'] | void
  >(() => {
    if (!columnFilters.length) return
    const activeFilter = filters.find((filter) =>
      equal(columnFilters, filter.filters)
    )

    return activeFilter?.id
  }, [columnFilters])

  if (!data.showManagerAdminContent()) return null

  return (
    <div className="flex gap-3 bg-gray-50 mb-5 text-green-600 items-center p-3 overflow-x-auto -mt-4 lg:-mt-10">
      <Sparkle size={20} weight="fill" className="flex-shrink-0" />
      <p className="mb-0 text-green-600 whitespace-nowrap">Quick filters:</p>
      {filters.map((filter) => (
        <WithAnalytics
          key={filter.id}
          event="$track_checkin_insights_assistant_clicked"
          params={{
            action: filter.id,
            source: 'checkin-insights-page',
          }}
        >
          <button
            className={cn(
              'px-3 py-1.5 rounded-full border transition-colors w-max whitespace-nowrap',
              activeFilter === filter.id
                ? 'bg-green-600 text-white !border-green-600'
                : 'bg-white border-gray-100 text-gray-600'
            )}
            onClick={() => {
              activeFilter === filter.id ? reset() : setFilters(filter.filters)
            }}
          >
            {filter.label}
          </button>
        </WithAnalytics>
      ))}
    </div>
  )
})
