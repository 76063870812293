import { GlobalConfirmationDialog } from 'src/design-system'
import * as React from 'react'
import { CheckinsTable } from './table'
import { CheckinsTableProvider } from './table/context/checkins-table'
import { store } from 'store/index'
import * as SecondaryNav from 'components/secondary-nav'
import { CheckinsDownloadButton } from './download-button'
import { CreateFormModalButton } from 'components/create-form-modal'
import { useStore } from 'store/context'
import { Assistant } from './assistant/assistant'

export const CheckinsPage = () => {
  const [loading, setLoading] = React.useState(true)

  const { currentUser } = useStore()

  React.useEffect(() => {
    const fetchData = async () => {
      const fetchUsers = async () => {
        await store.users.fetchAll({
          page: { size: 9999 },
          include: ['position', 'team'],
        })
      }

      const fetchCheckins = async () => {
        await store.checkins.fetchAll({
          exclude: ['actions', 'checkin_skills', 'position'],
          page: { size: 9999 },
        })
      }

      await Promise.all([fetchUsers(), fetchCheckins()])

      setLoading(false)
    }

    fetchData()
  }, [])

  if (!currentUser) return null

  const showAdminManagerContent = currentUser.isAdmin || currentUser.isManager

  return (
    <>
      <GlobalConfirmationDialog id="assessor-select" />
      <CheckinsTableProvider loading={loading}>
        {({ columnFiltersObject, csvData }) => (
          <>
            <SecondaryNav.Root>
              <SecondaryNav.Header className="flex flex-row items-center justify-between w-full">
                Check-ins
                <div className="flex flex-row gap-2">
                  {showAdminManagerContent && (
                    <CheckinsDownloadButton
                      columnFilters={columnFiltersObject}
                      csvData={csvData}
                    />
                  )}
                  <CreateFormModalButton
                    className=""
                    colourVariant="theme"
                    label="Start check-in"
                    source="checkins_page"
                    variant="default"
                    tabs={['checkin']}
                    user={currentUser}
                  />
                </div>
              </SecondaryNav.Header>
            </SecondaryNav.Root>
            <main className="pt-4 lg:pt-10 overflow-x-auto flex-1 mb-10">
              <Assistant />
              <CheckinsTable />
            </main>
          </>
        )}
      </CheckinsTableProvider>
    </>
  )
}
