import { Checkin } from 'store/modules/checkins'
import { CHECKIN_SUMMARY_PAGE_TRACK_EVENT_NAME } from '../utils'
import { errorToast } from 'app/packs/src/utils/error-toast'
import { store } from 'store/index'
import { successToast } from 'app/packs/src/utils/success-toast'
import { trackEvent } from 'app/packs/src/services/event-tracker'
import { User } from 'store/modules/users'

export class DetailsSectionVm {
  constructor(private checkin: Checkin, private user: User) {}

  get description() {
    return this.checkin.description || '<p>Nothing added</p>'
  }

  get fixedHeight() {
    return !this.checkin.notes
  }

  get placeholderText() {
    return this.checkin.author.id === this.user.id
      ? 'How are you doing in general?'
      : `How is ${this.checkin.author.fname} doing in general?`
  }

  get readonlyContent() {
    return this.summaryContent || '<p>Nothing added</p>'
  }

  get summaryContent() {
    return this.checkin.notes || undefined
  }

  async onBlur(content: string) {
    if (this.contentUpdated(content)) {
      const result = await store.checkins.update(this.checkin.id, {
        notes: content,
      })

      if (result?.success) {
        successToast('Summary updated!')
      } else {
        errorToast('Failed to update summary')
      }

      trackEvent(CHECKIN_SUMMARY_PAGE_TRACK_EVENT_NAME, {
        action: 'summary-notes',
      })
    }
  }

  private contentUpdated(content: string) {
    return !['', this.summaryContent].includes(content)
  }
}
