import { observer } from 'mobx-react-lite'
import { Tooltip } from 'recharts'
import * as React from 'react'
import { ChartsVm } from './charts-vm'
import { Checkin } from 'store/modules/checkins'
import { CheckinProgressIndicator } from 'components/checkin-progress-indicator'
import { ComparePositionSelect } from './compare-position-select'
import { RadarChartHoverCard } from './radar-chart-hover-card'
import { SkillChartsLegend } from 'components/skill-charts-legend'
import { SkillRatioDonutChart } from 'components/skill-ratio-donut-chart'
import { SkillsRadarChart } from 'components/skills-radar-chart'

type ChartsProps = {
  checkin: Checkin
}

export const Charts = observer((props: ChartsProps) => {
  const { checkin } = props

  const [positionId, setPositionId] = React.useState<string | undefined>(
    undefined
  )

  const vm = React.useMemo(() => {
    return new ChartsVm(checkin, positionId)
  }, [checkin, positionId])

  const onChange = (positionId: string) => {
    setPositionId(positionId)
    vm.applyPositionChange(positionId)
  }

  const comparison = vm.comparison

  return (
    <>
      <div className="max-w-[300px] mt-14">
        <p className="mb-2 text-gray-600 text-center text-sm">
          Compare skill profile to:
        </p>
        <ComparePositionSelect
          onChange={(positionId) => {
            if (positionId) onChange(positionId)
          }}
          options={vm.options}
          placeholder={vm.positionName}
        />
        {comparison && (
          <div className="flex flex-col gap-3 items-center mt-5">
            <CheckinProgressIndicator
              className="h-2 max-w-[200px]"
              max={comparison.max}
              status={comparison.status}
              value={comparison.value}
            />
            {comparison.text && (
              <p className="text-gray-900 text-sm">
                <span className="font-semibold">{comparison.text}</span>
              </p>
            )}
          </div>
        )}
      </div>
      <div className="flex flex-col md:flex-row gap-y-4 items-center justify-center mb-8 gap-x-28 mt-14">
        <div className="flex flex-col items-center">
          <h3 className="font-bold text-gray-900 text-xl">Skill map</h3>
          <p className="mb-5 text-gray-600 text-xs">
            Current level vs required
          </p>
          <div className="relative">
            <SkillsRadarChart series={vm.series} size={300}>
              <Tooltip
                content={<RadarChartHoverCard positionName={vm.positionName} />}
                cursor={{ fill: 'transparent' }}
                position={{ x: -10, y: 280 }}
                wrapperStyle={{ zIndex: 10 }}
              />
            </SkillsRadarChart>
          </div>
        </div>
        <SkillRatioDonutChart counts={vm.levelCounts} />
      </div>
      <div className="mb-24">
        <SkillChartsLegend noOfUsers={1} />
      </div>
    </>
  )
})
