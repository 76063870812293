import * as React from 'react'
import { Checkin } from 'store/modules/checkins'
import { CHECKIN_SUMMARY_PAGE_TRACK_EVENT_NAME } from '../utils'
import { CustomRadarDot } from 'components/skills-radar-chart/custom-radar-dot'
import { levelStatus } from 'app/packs/src/utils/checkin-helpers'
import { Position } from 'store/modules/positions'
import {
  SkillsRadarChartDataPoint,
  SkillsRadarChartSeries,
} from '../../../components/skills-radar-chart/skills-radar-chart.types'
import { sortAlphaNumberValues } from 'app/packs/src/utils/sort-helpers'
import { store } from 'store/index'
import { Team } from 'store/modules/teams'
import { trackEvent } from 'app/packs/src/services/event-tracker'

export class ChartsVm {
  constructor(private checkin: Checkin, private positionId?: string | null) {}

  get comparison() {
    if (!this.positionId || !this.position) return

    const requiredPositionSkills = store.positionSkills.forPosition(
      this.positionId
    )

    const finalLevelTotal = this.checkin.checkinSkills.reduce<number>(
      (total, checkinSkill) => {
        const requiredSkill = requiredPositionSkills.find(
          (ps) => ps.skill.id === checkinSkill.skill.id
        )

        if (!requiredSkill) return total
        if (checkinSkill.finalLevel) total += checkinSkill.finalLevel
        return total
      },
      0
    )

    if (finalLevelTotal === 0) return

    const requiredLevelTotal = requiredPositionSkills.reduce<number>(
      (total, positionSkill) => {
        total += positionSkill.level
        return total
      },
      0
    )

    const percentageDifference = Math.abs(
      100 - (requiredLevelTotal / finalLevelTotal) * 100
    ).toFixed(0)

    const status = levelStatus(finalLevelTotal, requiredLevelTotal)

    let text = null
    if (status === 'exceeding') text = `${percentageDifference}% above`
    if (status === 'meeting') text = 'Meeting'
    if (status === 'working_towards') text = `${percentageDifference}% away`

    return {
      max: Math.max(requiredLevelTotal, finalLevelTotal),
      status,
      text,
      value: finalLevelTotal,
    }
  }

  get levelCounts() {
    return this.skillIds.reduce<{
      exceeding: number
      meeting: number
      not_checked_in: number
      working_towards: number
    }>(
      (counts, skillId) => {
        const currentLevel = this.currentLevelForSkill(skillId)
        const requiredLevel = this.requiredLevelForSkill(skillId)

        if (currentLevel === null || requiredLevel === null) {
          counts.not_checked_in += 1
        } else if (currentLevel > requiredLevel) {
          counts.exceeding += 1
        } else if (currentLevel === requiredLevel) {
          counts.meeting += 1
        } else {
          counts.working_towards += 1
        }

        return counts
      },
      { exceeding: 0, meeting: 0, not_checked_in: 0, working_towards: 0 }
    )
  }

  get options() {
    const options: Record<
      string,
      { code: string; label: string; value: string }[]
    > = {}

    this.sortedTeams.forEach((team) => {
      this.positions.forEach((position) => {
        if (position.team?.id !== team.id) return

        position.disciplines.forEach((discipline) => {
          if (!discipline) return
          options[team.name] = options[team.name] || []

          options[team.name].push({
            code: position.positionCode(discipline.initials),
            label: this.positionOptionLabel(position),
            value: position.id,
          })
        })
      })
    })

    return Object.entries(options).map(([teamName, options]) => ({
      label: teamName,
      options: options
        .sort((a, b) => sortAlphaNumberValues(a.code, b.code))
        .map((option) => {
          return { label: option.label, value: option.value }
        }),
    }))
  }

  get positionName() {
    return this.position?.name || this.checkin.positionName || 'No position'
  }

  get series() {
    return {
      required: {
        colour: '#CECACA',
        skills: this.requiredSkills,
      },
      current: {
        colour: '#0D592D',
        dot: (chartData) => {
          const data = chartData.payload
          const payload = data.payload

          let colour = '#D9A817'
          if (payload.current > payload.required) colour = '#267D50'
          if (payload.current === payload.required) colour = '#38B776'
          if (payload.meta === 'not_checked_in') colour = '#FFFFFF'

          return (
            <CustomRadarDot
              cx={data.x}
              cy={data.y}
              colour={colour}
              key={chartData.key}
            />
          )
        },
        skills: this.currentSkills,
      },
    } satisfies SkillsRadarChartSeries
  }

  applyPositionChange(positionId: string) {
    this.fetchPositionSkills(positionId)
    this.trackEvent('compare-position')
  }

  trackEvent(action: string) {
    trackEvent(CHECKIN_SUMMARY_PAGE_TRACK_EVENT_NAME, {
      action,
    })
  }

  private get authorsPositionId() {
    return this.checkin.author.position?.id
  }

  private get currentSkills() {
    return this.checkin.checkinSkills.reduce<SkillsRadarChartDataPoint[]>(
      (skills, checkinSkill) => {
        const requiredSkill = this.requiredSkills.find(
          (skill) => skill.id === checkinSkill.skill.id
        )

        if (requiredSkill && checkinSkill.finalLevel !== null) {
          skills.push({
            id: checkinSkill.skill.id,
            category: checkinSkill.categoryName || 'Uncategorised',
            level: checkinSkill.finalLevel,
            name: checkinSkill.skill.name,
          })
        }

        return skills
      },
      []
    )
  }

  private get position() {
    if (!this.positionId) return null

    return store.positions.byId(this.positionId)
  }

  private get positions() {
    return store.positions.forOrg
  }

  private get requiredSkills() {
    if (this.positionId) {
      return store.positionSkills
        .forPosition(this.positionId)
        .map((positionSkill) => {
          return {
            id: positionSkill.skill.id,
            category: positionSkill.categoryName || 'Uncategorised',
            level: positionSkill.level,
            meta: this.checkinSkillForSkill(positionSkill.skill.id)
              ? undefined
              : 'not_checked_in',
            name: positionSkill.skill.name,
          }
        })
    } else {
      return this.checkin.checkinSkills.map((checkinSkill) => {
        return {
          id: checkinSkill.skill.id,
          category: checkinSkill.categoryName || 'Uncategorised',
          level: checkinSkill.requiredLevel,
          name: checkinSkill.skill.name,
        }
      })
    }
  }

  private get skillIds() {
    const currentSkillIds = this.currentSkills.map((skill) => skill.id)
    const requiredSkillIds = this.requiredSkills.map((skill) => skill.id)

    return [...new Set(currentSkillIds.concat(requiredSkillIds))]
  }

  private get sortedTeams() {
    const authorsTeam = this.checkin.author.team
    const defaultTeams = authorsTeam ? [authorsTeam] : []

    return defaultTeams.concat(
      this.positions
        .reduce<Team[]>((teams, position) => {
          if (
            position.team &&
            !teams.find((team) => team.id === position.team.id) &&
            authorsTeam?.id !== position.team.id
          ) {
            teams.push(position.team)
          }

          return teams
        }, [])
        .sort((a, b) => (a.name > b.name ? 1 : -1))
    )
  }

  private checkinSkillForSkill(skillId: string) {
    return this.checkin.checkinSkills.find(
      (checkinSkill) => checkinSkill.skill.id === skillId
    )
  }

  private currentLevelForSkill(skillId: string) {
    const checkinSkill = this.checkinSkillForSkill(skillId)

    return checkinSkill ? checkinSkill.finalLevel : null
  }

  private positionOptionLabel(position: Position) {
    if (position.id !== this.authorsPositionId) return position.name

    const str =
      this.checkin.author.id === store.currentUser?.id
        ? 'your'
        : `${this.checkin.author.fname}'s`

    return `${position.name} (${str} position)`
  }

  private requiredLevelForSkill(skillId: string) {
    const requiredSkill = this.requiredSkills.find(
      (requiredSkill) => requiredSkill.id === skillId
    )

    return requiredSkill ? requiredSkill.level : null
  }

  private async fetchPositionSkills(positionId: string) {
    return await store.positionSkills.fetchForPosition(positionId)
  }
}
