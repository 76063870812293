import {
  CellContext,
  ColumnFiltersState,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  Table,
  useReactTable,
} from '@tanstack/react-table'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { CheckinInsightsData, CheckinTableRow } from '../data'
import { fuzzyFilter } from 'app/packs/src/utils/table-helpers'
import { store } from 'store/index'
import { SkeletonCell } from 'components/table/skeleton'

type CheckinsTableContextProps = {
  globalFilter: string
  setGlobalFilter: (value: string) => void
  columnFilters: ColumnFiltersState
  setColumnFilters: (value: ColumnFiltersState) => void
  // Used internally for tracking events
  columnFiltersObject: Record<string, string>
  data: CheckinInsightsData
  table: Table<CheckinTableRow>
  csvData: () => string
  loading: boolean
}

const CheckinsTableContext =
  React.createContext<CheckinsTableContextProps | null>(null)

export const CheckinsTableProvider = observer(
  ({
    children,
    loading = false,
  }: {
    children:
      | React.ReactNode
      | ((ctx: CheckinsTableContextProps) => React.ReactNode)
    loading?: boolean
  }) => {
    const [globalFilter, setGlobalFilter] = React.useState('')

    const [columnFilters, setColumnFilters] =
      React.useState<ColumnFiltersState>([])

    const vm = React.useMemo(() => {
      return new CheckinInsightsData()
    }, [])

    const data = React.useMemo(
      () => {
        if (loading) return vm.skeletonCheckins()
        return vm.data()
      },
      [vm, loading, store.checkins.all.length] // eslint-disable-line react-hooks/exhaustive-deps
    )

    const columns = React.useMemo(() => {
      const cols = vm.columns()
      if (!loading) return cols

      return cols.map((col) => ({
        ...col,
        cell: (ctx: CellContext<CheckinTableRow, unknown>) => {
          if (ctx.column.id === 'actions') return null
          const nameCell = ctx.column.id === 'name'
          return <SkeletonCell twoLines={nameCell} avatar={nameCell} />
        },
      }))
    }, [vm, loading])

    const table = useReactTable({
      data,
      columns,
      getCoreRowModel: getCoreRowModel(),
      getFilteredRowModel: getFilteredRowModel(),
      getSortedRowModel: getSortedRowModel(),
      filterFns: {
        fuzzy: fuzzyFilter,
      },
      globalFilterFn: fuzzyFilter,
      state: {
        columnFilters,
        columnVisibility: {
          checkinType: false,
          team: false,
          needsAction: false,
          manager: vm.showManagerAdminContent(),
        },
        globalFilter,
      },
      initialState: {
        sorting: [
          { id: 'progress', desc: false },
          { id: 'lastUpdated', desc: true },
        ],
      },
      onColumnFiltersChange: setColumnFilters,
      onGlobalFilterChange: setGlobalFilter,
    })

    const csvData = React.useCallback(() => {
      return vm.csvData(table.getRowModel())
    }, [vm, table])

    const columnFiltersObject = React.useMemo(() => {
      return columnFilters.reduce<Record<string, string>>((prev, next) => {
        if (next.value) {
          prev[next.id] = String(next.value)
        }

        return prev
      }, {})
    }, [columnFilters])

    const contextValue = {
      columnFilters,
      setColumnFilters,
      columnFiltersObject,
      globalFilter,
      setGlobalFilter,
      table,
      data: vm,
      csvData,
      loading,
    }

    const content =
      children instanceof Function ? children(contextValue) : children

    return (
      <CheckinsTableContext.Provider value={contextValue}>
        {content}
      </CheckinsTableContext.Provider>
    )
  }
)

export const useCheckinsTable = () => {
  const context = React.useContext(CheckinsTableContext)
  if (context === null) {
    throw new Error(
      'useCheckinsTable must be used within a CheckinsTableProvider'
    )
  }
  return context
}
