import { Checkin } from 'store/modules/checkins'

export class OverviewVm {
  constructor(private checkin: Checkin) {}

  get percentageDifferenceText() {
    if (
      this.checkin.summaryStatus === 'meeting' ||
      !this.checkin.summaryPercentageDifference
    ) {
      return 'Completed'
    }

    return `${this.checkin.summaryPercentageDifference}% ${
      this.checkin.summaryStatus === 'exceeding' ? 'above' : 'away'
    }`
  }

  get prettifiedSummaryStatus() {
    if (!this.checkin.summaryStatus) return

    return (
      this.checkin.summaryStatus.charAt(0).toUpperCase() +
      this.checkin.summaryStatus.slice(1)
    ).replaceAll('_', ' ')
  }

  get summaryUserText() {
    return this.checkin.author.isCurrentUser
      ? 'You were '
      : `${this.checkin.author.fname} was `
  }
}
