import { Avatar, Button } from 'src/design-system'
import { CellContext } from '@tanstack/react-table'
import * as React from 'react'
import { CheckinTableRow } from '../data'

export const NameCell = (data: CellContext<CheckinTableRow, unknown>) => {
  const { positionName, authorName, authorAvatarUrl, slug } = data.row.original

  const [fname, lname] = authorName.split(' ')
  const initials = fname[0] + (lname ? lname[0] : '')

  return (
    <div className="flex flex-row gap-x-3 items-center">
      <Avatar
        size="sm"
        className="flex-shrink-0"
        fallbackText={initials}
        src={authorAvatarUrl}
        alt={`Avatar for ${authorName}`}
      />
      <div className="flex flex-col">
        <p className="font-bold m-0 w-max">{authorName}</p>
        {positionName && (
          <span className="text-gray-600 text-xs whitespace-nowrap truncate">
            {positionName}
          </span>
        )}
      </div>
      {slug && (
        <Button
          as="a"
          href={`/checkins/${slug}?ref=checkins`}
          variant="outline"
          size="sm"
          className="ml-auto opacity-0 group-hover/row:opacity-100 transition-opacity"
          data-turbo-frame="content"
          data-turbo-action="advance"
        >
          View Check-in →
        </Button>
      )}
    </div>
  )
}
