import { ConfirmationDialog, OverflowMenu } from 'src/design-system'
import { CellContext } from '@tanstack/react-table'
import { errorToast } from 'app/packs/src/utils/error-toast'
import { successToast } from 'app/packs/src/utils/success-toast'
import { DownloadSimple, Eye, PencilSimple, Trash } from '@phosphor-icons/react'
import * as React from 'react'
import { store } from 'store/index'
import { CheckinTableRow } from '../data'
import { CheckinInsightsTableActionsCellVm } from './actions-vm'

export const ActionsCell = (data: CellContext<CheckinTableRow, unknown>) => {
  const row = data.row.original
  const checkin = row.id && store.checkins.byId(row.id)

  const onDestroy = React.useCallback(async () => {
    if (!checkin) return

    const result = await checkin.destroy()
    if (result?.success) {
      successToast('Check-in deleted!')
    } else {
      errorToast()
    }
  }, [checkin])

  const actionsVm = React.useMemo(() => {
    if (!checkin) return null
    return new CheckinInsightsTableActionsCellVm(checkin)
  }, [checkin])

  if (!checkin) return null

  return (
    <ConfirmationDialog.Root onConfirm={onDestroy} title="Are you sure?">
      <div className="flex flex-row gap-4 min-w-[50px]">
        <OverflowMenu.Root right>
          {actionsVm?.canEdit && (
            <OverflowMenu.Item>
              <a
                className="flex flex-row items-center gap-x-1 text-gray-900"
                href={checkin.path}
              >
                <PencilSimple
                  aria-hidden
                  className="text-gray-600 w-3.5 h-3.5"
                  weight="bold"
                />
                Edit
              </a>
            </OverflowMenu.Item>
          )}
          {actionsVm?.canView && (
            <OverflowMenu.Item>
              <a
                className="flex flex-row items-center gap-x-1 text-gray-900"
                href={checkin.path}
              >
                <Eye
                  aria-hidden
                  className="text-gray-600 w-3.5 h-3.5"
                  weight="bold"
                />
                View
              </a>
            </OverflowMenu.Item>
          )}
          <OverflowMenu.Item>
            <a
              className="flex flex-row items-center gap-x-1 text-gray-900"
              href={`${checkin.path}/csv`}
            >
              <DownloadSimple
                aria-hidden
                className="text-gray-600 w-3.5 h-3.5"
                weight="bold"
              />
              Download
            </a>
          </OverflowMenu.Item>
          {actionsVm?.canDestroy && (
            <ConfirmationDialog.Trigger asChild>
              <OverflowMenu.Item variant="destructive">
                <div className="flex flex-row items-center gap-x-1">
                  <Trash
                    aria-hidden
                    className="text-gray-600 w-3.5 h-3.5"
                    weight="bold"
                  />
                  Delete
                </div>
              </OverflowMenu.Item>
            </ConfirmationDialog.Trigger>
          )}
        </OverflowMenu.Root>
      </div>
    </ConfirmationDialog.Root>
  )
}
