import { CellContext } from '@tanstack/react-table'
import { CheckinAssessorSelect } from 'components/checkin-assessor-select'
import * as React from 'react'
import { useStore } from 'store/context'
import { CheckinTableRow } from '../data'

export const AssessorCell = (data: CellContext<CheckinTableRow, unknown>) => {
  const { currentUser } = useStore()
  const { id, assessorId, assessorName } = data.row.original
  if (!id || !assessorId) return '-'

  return (
    <div className="flex flex-col min-w-[200px]">
      {currentUser?.isAdmin ? (
        <CheckinAssessorSelect
          checkinId={id}
          userId={assessorId}
          showAvatarOnControl={false}
        />
      ) : (
        <div>{assessorName}</div>
      )}
    </div>
  )
}
