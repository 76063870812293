import { Checkin } from 'store/modules/checkins'
import { CheckinSkill } from 'store/modules/checkin-skills'

export class StrengthsAndAreasToImproveSectionVm {
  constructor(private checkin: Checkin) {}

  get improvementCheckinSkills() {
    return this.checkin.checkinSkills
      .filter((checkinSkill) => this.delta(checkinSkill) < 1)
      .sort((a, b) => this.delta(a) - this.delta(b))
      .slice(0, 5)
  }

  get strengthCheckinSkills() {
    return this.checkin.checkinSkills
      .filter((checkinSkill) => this.delta(checkinSkill) > 0)
      .sort((a, b) => this.delta(b) - this.delta(a))
      .slice(0, 5)
  }

  private delta(checkinSkill: CheckinSkill) {
    return (checkinSkill.finalLevel || 0) - checkinSkill.requiredLevel
  }
}
