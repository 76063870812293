import {
  createColumnHelper,
  RowModel,
  Row,
  CellContext,
} from '@tanstack/react-table'
import { isWithinInterval, subDays, subMonths } from 'date-fns'
import {
  ActionsCell,
  AssessorCell,
  FinalLevelCell,
  NameCell,
  ProgressCell,
} from './cells'
import { Checkin } from 'store/modules/checkins'
import {
  CHECKIN_LEVEL_LABELS,
  CHECKIN_STAGE_LABELS,
  CHECKIN_TYPE_LABELS,
  CheckinLevel,
  checkinLevels,
  CheckinStage,
  checkinTypes,
  TIME_PERIOD_LABELS,
  TimePeriod,
  timePeriods,
} from './utils/select.types'
import {
  formatDate,
  formatDateWithTime,
} from 'app/packs/src/utils/date-helpers'
import { store } from 'store/index'
import { User } from 'store/modules/users'
import { sortByProgress } from './utils/sorts'
import { ManagerSelectValue } from 'components/manager-user-select/manager-user-select.types' // eslint-disable-line import/extensions
import { stripHtmlTags } from 'app/packs/src/utils/string-helpers'

export type CheckinTableRow = {
  id?: string
  slug?: string
  authorName: string
  authorId: string
  authorAvatarUrl?: string
  authorTeamName?: string
  authorTeamId?: string
  managerName?: string
  managerId?: string
  assessorName?: string
  assessorId?: string
  positionName?: string
  createdAt?: Date
  updatedAt?: Date
  completedAt?: Date
  status?: Checkin['status']
  stage?: CheckinStage
  finalLevel: CheckinLevel
  checkinType?: 'latest'
  needsAction?: boolean
  title?: string
  description?: string
  notes?: string
}

export class CheckinInsightsData {
  private get relevantCheckins() {
    if (!this.showManagerAdminContent()) {
      return store.checkins.forAuthor(this.currentUser.id)
    }

    return store.checkins.forNonArchivedUsers
  }

  data(): CheckinTableRow[] {
    if (!this.showManagerAdminContent()) {
      return this.relevantCheckins.map(this.convertCheckinToRowModel)
    }

    return [
      ...this.relevantCheckins.map(this.convertCheckinToRowModel),
      ...this.fakeCheckins(),
    ]
  }

  columnHelper = createColumnHelper<CheckinTableRow>()
  private currentUser = store.nonNullCurrentUser

  get assessors() {
    return store.checkins.all
      .reduce<User[]>((assessors, checkin) => {
        const assessor = checkin.assessor
        if (assessor && !assessors.includes(assessor)) assessors.push(assessor)

        return assessors
      }, [])
      .sort((a, b) => a.fullName.localeCompare(b.fullName))
  }

  private fakeCheckins(): CheckinTableRow[] {
    const relevantUsers = this.currentUser.isAdmin
      ? store.users.active
      : this.currentUser.allReports

    return relevantUsers.flatMap((user) => {
      const hasCheckin = !!store.checkins.all.find(
        (checkin) => checkin.author.id === user.id
      )
      if (hasCheckin) return []

      return {
        authorName: user.fullName,
        authorId: user.id,
        authorAvatarUrl: user.avatarUrl || undefined,
        authorTeamId: user.team?.id,
        authorTeamName: user.team?.name,
        positionName: user.position?.name,
        positionId: user.position?.id,
        finalLevel: 'not_checked_in',
      }
    })
  }

  skeletonCheckins(): CheckinTableRow[] {
    return Array.from({ length: 10 }).map(() => ({
      authorName: '',
      authorId: '',
      finalLevel: 'not_checked_in',
    }))
  }

  showManagerAdminContent() {
    return this.currentUser.isAdmin || this.currentUser.isManager
  }

  columns() {
    return [
      this.columnHelper.accessor('authorName', {
        header: 'Name',
        cell: NameCell,
        filterFn: (
          row: Row<CheckinTableRow>,
          _columnId: string,
          filterValue: ManagerSelectValue | string
        ) => {
          const author = store.users.byId(row.original.authorId)
          if (!author) return false
          switch (filterValue) {
            case ManagerSelectValue.Everybody:
              return true
            case ManagerSelectValue.YouAndAllReportingLine:
              return (
                author.isCurrentUser ||
                !!store.nonNullCurrentUser.allReports.find(
                  (user) => user.id === author.id
                )
              )
            case ManagerSelectValue.YouAndDirectReports:
              return (
                author.isCurrentUser ||
                !!store.nonNullCurrentUser.directReports.find(
                  (user) => user.id === author.id
                )
              )
            case ManagerSelectValue.AllReportingLine:
              return !author.isCurrentUser
            case ManagerSelectValue.DirectReports:
              return !!store.nonNullCurrentUser?.directReports.find(
                (user) => user.id === author.id
              )
            default:
              return author.id === filterValue
          }
        },
        id: 'name',
      }),
      this.columnHelper.accessor('authorTeamId', {
        header: 'Team',
        id: 'team',
        enableGlobalFilter: false,
        filterFn: 'arrIncludesSome',
      }),
      this.columnHelper.accessor('managerName', {
        header: 'Manager',
        cell: (row) => row.getValue() || '-',
        id: 'manager',
      }),
      this.columnHelper.accessor('assessorName', {
        cell: AssessorCell,
        header: 'Assessor',
        id: 'assessor',
        filterFn: (
          row: Row<CheckinTableRow>,
          _columnId: string,
          filterValue: string[] = []
        ) => {
          if (!filterValue.length) return true
          if (!row.original.assessorId) return false
          return filterValue.includes(row.original.assessorId)
        },
      }),
      this.columnHelper.accessor('title', {
        header: 'Title',
        cell: (row) => row.getValue() || '-',
        id: 'title',
      }),
      this.columnHelper.accessor('stage', {
        cell: ProgressCell,
        filterFn: 'arrIncludesSome',
        header: 'Check-in Progress',
        id: 'progress',
        sortingFn: sortByProgress,
      }),
      this.columnHelper.accessor('finalLevel', {
        cell: FinalLevelCell,
        filterFn: 'arrIncludesSome',
        header: 'Final Level',
        id: 'finalLevel',
      }),
      this.columnHelper.accessor('createdAt', {
        cell: this.renderDate,
        filterFn: (
          row: Row<CheckinTableRow>,
          _columnId: string,
          filterValue: TimePeriod
        ) => {
          if (filterValue === 'all time') return true

          return row.original.createdAt
            ? this.withinTimePeriod(row.original.createdAt, filterValue)
            : false
        },
        header: 'Started',
        id: 'started',
        sortingFn: 'datetime',
      }),
      this.columnHelper.accessor('updatedAt', {
        cell: this.renderDate,
        header: 'Last Updated',
        id: 'lastUpdated',
        sortingFn: 'datetime',
      }),
      this.columnHelper.accessor('completedAt', {
        header: 'Completed',
        cell: this.renderDate,
        id: 'completed',
        sortingFn: 'datetime',
      }),
      this.columnHelper.display({
        cell: ActionsCell,
        id: 'actions',
      }),
      this.columnHelper.accessor('checkinType', { id: 'checkinType' }),
      this.columnHelper.accessor('needsAction', { id: 'needsAction' }),
    ]
  }

  get levelOptions() {
    return checkinLevels.map((value) => ({
      label: CHECKIN_LEVEL_LABELS[value],
      value,
    }))
  }

  get stageOptions() {
    return Object.entries(CHECKIN_STAGE_LABELS).map(([value, label]) => ({
      value,
      label,
    }))
  }

  get teamOptions() {
    return store.teams.sortedForCurrentUser.map((team) => ({
      label: team.name,
      value: team.id,
    }))
  }

  get timeOptions() {
    return timePeriods.map((value) => ({
      label: TIME_PERIOD_LABELS[value],
      value,
    }))
  }

  get typeOptions() {
    return checkinTypes.map((value) => ({
      label: CHECKIN_TYPE_LABELS[value],
      value,
    }))
  }

  csvData(rowModel: RowModel<CheckinTableRow>) {
    const data: string[] = []

    data.push(
      'Name,Email,Position,Team,Manager,Assessor,Title,Description,Summary,Progress,Final Level,Started,Last Updated,Completed'
    )

    this.checkinsFromRowModel(rowModel).forEach((checkin) => {
      const author = checkin.authorId && store.users.byId(checkin.authorId)
      if (!author) return

      data.push(
        [
          author.fullName,
          author.email,
          author.position?.name || '-',
          author.team?.name || '-',
          checkin.managerName || '-',
          checkin.assessorName || '-',
          checkin.title || '-',
          stripHtmlTags(checkin.description) || '-',
          stripHtmlTags(checkin.notes) || '-',
          checkin.stage ? CHECKIN_STAGE_LABELS[checkin.stage] : 'Not started',
          checkin.finalLevel || '-',
          checkin.createdAt
            ? `"${formatDateWithTime(checkin.createdAt)}"`
            : '-',
          checkin.updatedAt
            ? `"${formatDateWithTime(checkin.updatedAt)}"`
            : '-',
          checkin.completedAt
            ? `"${formatDateWithTime(checkin.completedAt)}"`
            : '-',
        ].join(',')
      )
    })

    return data.join('%0A')
  }

  completedCheckinCount(rowModel: RowModel<CheckinTableRow>) {
    return this.checkinsFromRowModel(rowModel).filter(
      (checkin) => !!checkin.completedAt
    ).length
  }

  totalCheckinCount(rowModel: RowModel<CheckinTableRow>) {
    return this.checkinsFromRowModel(rowModel).filter((checkin) => !!checkin.id)
      .length
  }

  private convertCheckinToRowModel = (
    checkin: Checkin,
    _: number,
    list: Checkin[]
  ): CheckinTableRow => {
    const stage = this.convertStatusToStage(checkin.status)

    return {
      id: checkin.id,
      slug: checkin.slug,
      authorName: checkin.author.fullName,
      authorId: checkin.author.id,
      authorAvatarUrl: checkin.author.avatarUrl || undefined,
      authorTeamName: checkin.author.team?.name,
      authorTeamId: checkin.author.team?.id,
      managerName: checkin.author.manager?.fullName,
      managerId: checkin.author.manager?.id,
      assessorName: checkin.assessor?.fullName,
      assessorId: checkin.assessor?.id,
      positionName: checkin.positionName || undefined,
      createdAt: checkin.createdAt,
      updatedAt: checkin.updatedAt,
      completedAt: checkin.finalisedAt || undefined,
      status: checkin.status,
      stage,
      title: checkin.title || undefined,
      description: checkin.description || undefined,
      notes: checkin.notes || undefined,
      finalLevel: this.convertSummaryStatus(checkin.summaryStatus),
      checkinType: this.isLatestCheckin(checkin, list),
      needsAction:
        (checkin.author.isCurrentUser &&
          ['step_1', 'step_3'].includes(stage)) ||
        (checkin.assessor?.isCurrentUser &&
          ['step_2', 'step_3'].includes(stage)),
    }
  }

  private isLatestCheckin(checkin: Checkin, list: Checkin[]) {
    const firstIndex = list.findIndex(
      (c) => c.id && c.author.id === checkin.author.id
    )
    const thisIndex = list.findIndex((c) => c.id && c.id === checkin.id)
    if (firstIndex === thisIndex) return 'latest'
  }

  private checkinsFromRowModel(rowModel: RowModel<CheckinTableRow>) {
    return rowModel.rows.map((row) => row.original)
  }

  private convertStatusToStage(status: Checkin['status']): CheckinStage {
    switch (status) {
      case 'not_started':
      case 'in_progress':
        return 'step_1'
      case 'pending_assessor':
      case 'assessor_in_progress':
        return 'step_2'
      case 'pending_review':
      case 'review_in_progress':
        return 'step_3'
      case 'finalised':
        return 'step_4'
    }
  }

  private convertSummaryStatus(summaryStatus: Checkin['summaryStatus']) {
    return summaryStatus || 'not_checked_in'
  }

  private convertTimePeriodToDate(timePeriod: TimePeriod) {
    const date = new Date()
    date.setHours(0, 0, 0, 0)

    switch (timePeriod) {
      case '30 days':
        return subDays(date, 30)
      case '3 months':
        return subMonths(date, 3)
      case '6 months':
        return subMonths(date, 6)
      case '12 months':
        return subMonths(date, 12)
      default:
        return new Date(-8640000000000000)
    }
  }

  private withinTimePeriod(createdAt: Date, timePeriod: TimePeriod) {
    return timePeriod === 'all time'
      ? true
      : isWithinInterval(createdAt, {
          start: this.convertTimePeriodToDate(timePeriod),
          end: new Date(),
        })
  }

  private renderDate(row: CellContext<CheckinTableRow, Date | undefined>) {
    const date = row.getValue()
    return date ? formatDate(date, { relative: true }) : '-'
  }
}
