import { useConfirmationDialogContext } from 'src/design-system'
import { UserSelect } from 'components/user-select'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { useStore } from 'store/context'
import { errorToast } from '../../utils/error-toast'
import { successToast } from '../../utils/success-toast'
import { ConfirmationDialogProps } from '../../design-system/components/molecules/ConfirmationDialog'

type CheckinAssessorProps = {
  checkinId: string
  userId: string
  /**
   * Whether or not to show the avatar on the control input (even if false, the avatars in the option list will still show)
   */
  showAvatarOnControl?: boolean
}

export const CheckinAssessorSelect = observer((props: CheckinAssessorProps) => {
  const { userId, checkinId, showAvatarOnControl = true } = props
  const { users, checkins, currentUser } = useStore()

  const { openConfirmationDialog } = useConfirmationDialogContext()

  const onChange = (userId: string | null) => {
    const user = userId && users.byId(userId)
    if (!user) {
      return
    }

    openConfirmationDialog('assessor-select', {
      title: 'Change Check-in assessor?',
      body: `${user.fullName} will be responsible for completing this Check-in.`,
      confirmLabel: 'Update assessor',
      onConfirm: async () => {
        const result = await checkins.update(checkinId, {
          assessor: user.id,
        })
        if (result?.success) {
          setSelectedUserId(user.id)
          successToast('Assessor updated')
        } else {
          errorToast('Failed to update assessor')
        }
      },
    } satisfies ConfirmationDialogProps)
  }

  const [selectedUserId, setSelectedUserId] = React.useState(userId)

  return (
    <UserSelect
      portalled
      isDisabled={!currentUser?.isAdmin}
      value={selectedUserId}
      key={`assessor-select-${checkinId}`}
      onChange={onChange}
      users={users.withPackage('grow')}
      isMulti={false}
      required={true}
      isClearable={false}
      showAvatarOnControl={showAvatarOnControl}
    />
  )
})
