import { flexRender } from '@tanstack/react-table'
import { GlobalConfirmationDialog } from 'src/design-system'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { useCheckinsTable } from './context/checkins-table'
import * as Table from 'components/table'
import { Filters } from './filters'
import { store } from 'store/index'

export const CheckinsTable = observer(() => {
  const { data, table, loading } = useCheckinsTable()

  const source = 'org-checkin-insights-table'

  React.useEffect(() => {
    table.resetColumnFilters()

    const search = new URLSearchParams(window.location.search)
    const userFilter = search.get('user_id')
    if (userFilter) {
      table.setColumnFilters([{ id: 'name', value: userFilter }])
    }
  }, [table])

  const percentageComplete =
    100 *
    (data.completedCheckinCount(table.getRowModel()) /
      data.totalCheckinCount(table.getRowModel()))

  function getNum(val: number) {
    if (isNaN(val)) {
      return 0
    }
    return val
  }

  return (
    <div>
      <div className="mb-4 lg:mb-6">
        <Filters />
        {store.nonNullCurrentUser.isAdmin && (
          <div className="px-4">
            <h3 className="text-base font-bold">
              {data.completedCheckinCount(table.getRowModel())} of{' '}
              {data.totalCheckinCount(table.getRowModel())} Check-ins completed
              ({getNum(percentageComplete).toFixed(0)}%)
            </h3>
          </div>
        )}
      </div>
      <div className="overflow-x-auto pb-10">
        <Table.Root>
          <Table.Head>
            {table.getHeaderGroups().map((headerGroup) => (
              <Table.Row key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <Table.Header
                      key={header.id}
                      header={header}
                      source={source}
                      loading={loading}
                    >
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                    </Table.Header>
                  )
                })}
              </Table.Row>
            ))}
          </Table.Head>

          <Table.Body>
            {table.getRowModel().rows.length === 0 && (
              <Table.NoResultsRow colspan={table.getAllColumns().length} />
            )}

            {table.getRowModel().rows.map((row, index) => {
              const cells = row.getVisibleCells()

              return (
                <Table.Row
                  showBottomBorder={index < table.getRowModel().rows.length - 1}
                  key={row.id}
                >
                  {cells.map((cell) => (
                    <Table.Cell key={cell.id} className="whitespace-nowrap">
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </Table.Cell>
                  ))}
                </Table.Row>
              )
            })}
          </Table.Body>
        </Table.Root>
      </div>
      <GlobalConfirmationDialog id={`assessor-select`} />
    </div>
  )
})
