import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { ActionsSection } from './actions-section'
import { Charts } from './charts'
import { CheckinSkillsSection } from './checkin-skills-section'
import { CheckinSummaryPageVm } from './checkin-summary-page-vm'
import { DetailsSection } from './details-section'
import { Header } from './header'
import { LoadingContent } from 'components/loading-content'
import { Overview } from './overview'
import { store } from 'store/index'
import { StrengthsAndAreasToImproveSection } from './strengths-and-areas-to-improve-section'
import { UpdatesDrawer } from 'components/updates-drawer'

type CheckinSummaryPageProps = {
  authorId: string
  checkinId: string
}

export const CheckinSummaryPage = observer((props: CheckinSummaryPageProps) => {
  const { authorId, checkinId } = props

  const [loading, setLoading] = React.useState(true)

  const vm = React.useMemo(() => {
    return new CheckinSummaryPageVm(authorId, checkinId)
  }, [authorId, checkinId])

  React.useEffect(() => {
    const fetchData = async () => {
      const fetchCheckins = async () => {
        await store.checkins.fetchAll({
          filter: { author_id: authorId, status: 'finalised' },
          include: [
            'assessor',
            'author',
            'checkin_skills',
            'checkin_skills.skill',
          ],
        })
      }

      const fetchFrameworks = async () => {
        await store.frameworks.fetchAll({
          include: [
            'frameworks_skills',
            'frameworks_skills.skill_variant',
            'frameworks_skills.skill_variant.skill',
          ],
        })
      }

      await Promise.all([fetchCheckins(), fetchFrameworks()])

      setLoading(false)

      store.actions.fetchForUser(authorId, {
        created_from_id: checkinId,
        created_from_type: 'Checkin',
      })

      store.userSkills.fetchForUser(authorId)
    }

    fetchData()
  }, [authorId, checkinId])

  const checkin = store.checkins.byId(checkinId)
  const user = store.currentUser

  if (!checkin || !user) return null

  const dateFrom = vm.dateFrom(checkin)
  const dateTo = vm.dateTo(checkin)
  const editable = vm.editable(checkin, user)

  return (
    <LoadingContent className="pt-10" loading={loading}>
      <main>
        <Header
          checkin={checkin}
          nextCheckin={vm.nextCheckin}
          previousCheckin={vm.previousCheckin}
        />
        <div className="container max-w-[880px] mx-auto py-24">
          <div className="flex flex-col items-center">
            <Overview checkin={checkin} />
            <Charts checkin={checkin} />
            <div className="flex flex-col gap-20 max-w-[632px] w-full">
              <StrengthsAndAreasToImproveSection checkin={checkin} />
              <DetailsSection
                checkin={checkin}
                editable={editable}
                user={user}
              />
              <ActionsSection checkin={checkin} editable={editable} />
              <CheckinSkillsSection
                checkin={checkin}
                dateFrom={dateFrom}
                dateTo={dateTo}
                editable={editable}
              />
            </div>
          </div>
        </div>
        <UpdatesDrawer
          fromDatetime={dateFrom.toISOString()}
          toDatetime={dateTo.toISOString()}
          skillIds={checkin.skills.map((skill) => skill.id)}
          userId={checkin.author.id}
        />
      </main>
    </LoadingContent>
  )
})
