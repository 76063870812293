import { Checkin } from 'store/modules/checkins'
import { store } from 'store/index'

export class CheckinInsightsTableActionsCellVm {
  constructor(private checkin: Checkin) {}

  get canDestroy() {
    return this.isCurrentUserAssessorOrAuthor || store.currentUser?.isAdmin
  }

  get canEdit() {
    return !this.checkin.isFinalised && this.isCurrentUserAssessorOrAuthor
  }

  get canView() {
    return this.checkin.isFinalised
  }

  private get isCurrentUserAssessorOrAuthor() {
    return store.currentUser
      ? this.checkin.userIds.includes(store.currentUser.id)
      : false
  }
}
