import { ColumnFiltersState } from '@tanstack/react-table'
import { store } from 'store/index'

type Filter = {
  id: string
  label: string
  filters: ColumnFiltersState
}
export const filters = [
  {
    id: 'needs_action',
    label: 'Needs action from you',
    filters: [{ id: 'needsAction', value: true }],
  },
  {
    id: 'youre_assessing',
    label: 'You’re assessing',
    filters: [{ id: 'assessor', value: [store.nonNullCurrentUser.id] }],
  },
  {
    id: 'complete',
    label: 'Complete',
    filters: [{ id: 'progress', value: ['step_4'] }],
  },
  {
    id: 'incomplete',
    label: 'Incomplete',
    filters: [{ id: 'progress', value: ['step_1', 'step_2', 'step_3'] }],
  },
  {
    id: 'no_checkin',
    label: 'Not checked in',
    filters: [{ id: 'progress', value: ['not_created'] }],
  },
  {
    id: 'exceeding',
    label: 'Exceeding',
    filters: [
      { id: 'checkinType', value: 'latest' },
      { id: 'progress', value: ['step_4'] },
      { id: 'finalLevel', value: ['exceeding'] },
    ],
  },
] satisfies Filter[]
