import {
  NameType,
  ValueType,
} from 'recharts/types/component/DefaultTooltipContent'
import { observer } from 'mobx-react-lite'
import { TooltipProps } from 'recharts'
import * as React from 'react'
import { CheckinStatusLabel } from 'components/checkin-status-label'
import { levelStatus } from 'app/packs/src/utils/checkin-helpers'
import { Tag } from 'src/design-system'

export const RadarChartHoverCard = observer(
  (props: TooltipProps<ValueType, NameType> & { positionName: string }) => {
    const { payload, positionName } = props

    const chartData = payload?.[0]

    if (!chartData) return null

    return (
      <div className="bg-white border border-gray-100 flex flex-col gap-y-1 min-w-[220px] p-3 rounded text-xs w-auto whitespace-nowrap">
        <p className="font-semibold mb-0 text-gray-900">
          {chartData.payload.label}
        </p>
        <p className="mb-3 text-gray-600">{chartData.payload.category}</p>
        <div className="flex gap-2 items-center mb-3">
          <CheckinStatusLabel
            status={
              chartData.payload.meta ||
              levelStatus(chartData.payload.current, chartData.payload.required)
            }
          />
          <p className="mb-0 text-gray-600">{positionName}</p>
        </div>
        <div className="flex">
          <p className="mb-0 mr-2 text-gray-600">Current</p>
          <Tag className="mr-4" variant="paper">
            {chartData.payload.meta === 'not_checked_in'
              ? 'N/A'
              : `L${chartData.payload.current}`}
          </Tag>
          <p className="mb-0 mr-2 text-gray-600">Required</p>
          <Tag variant="paper">L{chartData.payload.required}</Tag>
        </div>
      </div>
    )
  }
)
